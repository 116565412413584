<template>
<div class="overview">

    <div class="basic-1 cards-1">
        <div class="container">
            <div class="row">
            
                <div class="col-lg-12 ">
                    <h2>Learn new commands</h2>
                    <h2>{{topic}}</h2>
                    <br>

                    <div class="score">
                        <h3>Terminal</h3>
                    </div>
                    <div>
                        <a href="../overview/linux">
                            <div class="btn-green btn-div">Linux</div>
                        </a>&nbsp;
                        <a href="../overview/vim">
                            <div class="btn-green btn-div">Vim</div>
                        </a>&nbsp;
                        <a href="../overview/tmux">
                            <div class="btn-green btn-div">Tmux</div>
                        </a>&nbsp;
                        <a href="../overview/gpg">
                            <div class="btn-green btn-div">Gpg</div>
                        </a>&nbsp;
                        <a href="../overview/grep">
                            <div class="btn-green btn-div">Grep</div>
                        </a>&nbsp;
                        <br>
                        <a href="../overview/terminal">
                            <div class="btn-green btn-div">Terminal</div>
                        </a>&nbsp;
                         <a href="../overview/find">
                            <div class="btn-green btn-div">Find</div>
                        </a>&nbsp;
                          <a href="../overview/tar">
                            <div class="btn-green btn-div">Tar</div>
                        </a>&nbsp;
                           <a href="../overview/awk">
                            <div class="btn-green btn-div">Awk</div>
                        </a>&nbsp;
                    </div>
                    <div class="score">
                        <h3>Network & Security</h3>
                    </div>
                    <a href="../overview/network">
                        <div class="btn-green btn-div">Network</div>
                    </a>&nbsp;
                     <a href="../overview/ssh">
                        <div class="btn-green btn-div">SSH</div>
                    </a>&nbsp;
                     <a href="../overview/nmap">
                        <div class="btn-green btn-div">Nmap</div>
                    </a>&nbsp;
                    <a href="../overview/network_ports">
                        <div class="btn-green btn-div">Ports</div>
                    </a>&nbsp;
                     <a href="../overview/netstat">
                        <div class="btn-green btn-div">Netstat</div>
                    </a>&nbsp;
                    <br> 
                    <a href="../overview/ufw">
                        <div class="btn-green btn-div">UFW (Firewall)</div>
                    </a>&nbsp;
                    <a href="../overview/iptables">
                        <div class="btn-green btn-div">Iptables (FW)</div>
                    </a>&nbsp;
                     <a href="../overview/netstat">
                        <div class="btn-green btn-div">Netstat</div>
                    </a>&nbsp;
                    <a href="../overview/gpg">
                        <div class="btn-green btn-div">Gpg</div>
                    </a>&nbsp;
                    <a href="../overview/sftp">
                        <div class="btn-green btn-div">Sftp</div>
                    </a>&nbsp;
                    <div class="score">
                        <h3>OS/Distribution</h3>
                    </div>
                    <a href="../overview/ubuntu">
                        <div class="btn-green btn-div">Ubuntu</div>
                    </a>&nbsp;
                    <a href="../overview/fedora">
                        <div class="btn-green btn-div">Fedora</div>
                    </a>&nbsp;
                    <a href="../overview/debian">
                        <div class="btn-green btn-div">Debian</div>
                    </a>&nbsp;
                    <a href="../overview/openbsd">
                        <div class="btn-green btn-div">OpenBSD</div>
                    </a>&nbsp;
                    <div class="score">
                        <h3>Programming</h3>
                    </div>
                    <a href="../overview/git">
                        <div class="btn-green btn-div">Git</div>
                    </a>&nbsp;
                    <a href="../overview/docker">
                        <div class="btn-green btn-div">Docker</div>
                    </a>&nbsp;
                    <a href="../overview/vim">
                        <div class="btn-green btn-div">Vim</div>
                    </a>&nbsp;
                    <a href="../overview/sql">
                        <div class="btn-green btn-div">SQL</div>
                    </a>&nbsp;
                    <a href="../overview/network_ports">
                        <div class="btn-green btn-div">Ports</div>
                    </a>&nbsp;
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import router from '../router'

export default {
    components: {},
    name: 'HelloWorld',
    props: {
        msg: String
    },
    computed: {
        formattedElapsedTime() {
            const date = new Date(null);
            //date.setSeconds(this.elapsedTime / 1000);
            date.setMilliseconds(this.elapsedTime);
            const utc = date.toISOString();
            return utc.substr(utc.indexOf(".") - 2, 5) + 's';
        },
        id() {
            return this.$route.params.id;
        },
    },
    created: function () {

        this.topic = this.$route.params.topic;
        //this.$cookie.set(this.topic, '1');

        var c = this.$cookie.get(this.topic);
        if (c === null) {
            console.log('no cookie');
            this.$cookie.set(this.topic, '0');
            this.level = 1;
        } else {
            this.level = c;
        }

        this.topic = this.topic.charAt(0).toUpperCase() + this.topic.slice(1);

        /*var key = this.$cookie.get('key');
        
        console.log(key);

        if (key === null) 
            router.replace("/login");
        */
        this.getData();

    },
    methods: {
        getId: function () {
            return this.$route.params.id;
        },
        start: function () {
            this.startTimer();
        },
        onChange: function () {
            this.correct();
        },
        correct: function () {
            //console.log(' answer   = ', this.answer, this.answer.length, typeof this.answer);
            //console.log(' solution = ', this.data[this.index].solution, this.index, this.data[this.index].solution.length, typeof this.data[this.index].solution);
            if (this.data[this.index].solution == this.answer) {
                //console.log(this.index);
                //console.log(this.data.length);

                var myid = this.getId();

                if (this.index >= this.data.length - 1) {
                    console.log('next exercise');
                    this.index = 0;
                    this.score = 0;
                    var id = Number(myid) + 1;
                    this.id = id;
                    this.getData();
                    router.replace("/exercise/" + (id));
                }
                this.oldIndex = 0;
                //console.log('correct');
                this.answer = '';
                this.score = this.score + 1;
                this.index = this.index + 1;
                //console.log('index is ', this.index);
                this.totalTime += this.elapsedTime;
                this.calculateAverageTime();
                this.resetTimer();
                this.startTimer();

                //this.answer = this.data[this.index].placeholder;

                setTimeout(() => {
                    this.answer = this.data[this.index].placeholder;
                }, 10)

            }
        },
        reset: function () {
            console.log('reset');
            this.index = 0;
            this.score = 0;
            this.answer = this.data[this.index].placeholder;
            this.totalTime = 1;
            this.resetTimer();
            this.calculateAverageTime();
        },
        startTimer() {
            this.timer = setInterval(() => {
                this.elapsedTime += 101;
            }, 101);
        },
        stopTimer() {
            clearInterval(this.timer);
        },
        resetTimer() {
            this.elapsedTime = 1;
        },
        calculateAverageTime() {
            const date = new Date(null);
            //date.setSeconds(this.elapsedTime / 1001);
            date.setMilliseconds(this.totalTime / this.score);
            const utc = date.toISOString();
            this.averageTime = utc.substr(utc.indexOf(".") - 3, 5) + 's';
        },
        getData() {
            for (var i = 0; i < 20; i++) {
                fetch('../' + this.$route.params.topic + '/' + i + '.json')
                    .then(response => response.json())
                    .then(data => (this.data = data))
                    .then(response => {
                        this.answer = this.data[this.index].placeholder;
                        console.log('fetched data', response);

                        // topics
                        console.log(response[0].title)
                        this.topics.push(response[0].title)
                    });
            }
        },
    },
    data: function () {
        return {
            elapsedTime: 1,
            totalTime: 1,
            averageTime: 1,
            timer: undefined,
            answer: "",
            score: 0,
            index: 0,
            data: [{}, {}],
            title: '',
            exercise: [],
            topic: '',
            topics: [],
            level: 0,
            api: [

            ]
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@import '../assets/hero/css2.css';
@import '../assets/hero/normalize.css';
@import '../assets/hero/style.css';
@import '../assets/hero/responsive.css';

.score {
    background: #fff;
    color: rgb(37, 56, 88);
    padding: 10px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.buttons {
    margin-top: 12px;
}

.btn-div {
    display: inline-block;
}

.btn-green {
    background: rgb(0, 71, 179);
    border: 1px solid rgb(0, 71, 179);
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 45px;
    margin: 0 0 0em;
    max-width: 120px;
    position: relative;
    text-decoration: none;
    width: 100%;
    padding: 4px 4px;
    border-radius: 5px;
    margin-top: 5px;
}

.btn-green:hover {
    text-decoration: none;
    background: rgb(0, 51, 159);
}

.btn-blue {
    background: midnightblue;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 45px;
    margin: 0 0 2em;
    max-width: 160px;
    position: relative;
    text-decoration: none;
    width: 100%;
    padding: 16px 10px;
    border-radius: 5px;
}

.btn-blue a,
.btn-green a {
    color: white;
}

/* timeline */
:root {
    font-family: sans-serif;
}

ol {
    margin: 0;
    list-style: none;
    padding: 0;
    --hue: 1;
    --unit: 1rem;
}

h1 {
    text-align: center;
}

p {
    line-height: 1.3;
}

.event-date {
    margin: 0 0 0.25rem;
    font-weight: bold;
}

.event-description {
    margin: 0;
}

li {
    --height: 7rem;
    position: relative;
    display: block;
    background-color: rgb(245, 245, 246);
    border-color: rgb(191, 191, 204);
    padding: 1rem;
    margin: 2rem 0;
}

.lock {
    font-size: 32px;
}

li::before {
    content: "";
    background-color: inherit;
    position: absolute;
    display: block;
    width: var(--unit);
    height: var(--unit);
    top: 100%;
    left: calc(50% - (var(--unit)/2));
}

li::after {
    content: "";
    position: absolute;
    display: block;
    top: calc(100% + var(--unit));
    left: calc(50% - (var(--unit)));
    border: var(--unit) solid transparent;
    border-top-color: inherit;
}

li:last-child::before,
li:last-child::after {
    content: none;
}

/*
li:nth-child(20n+1){
  --hue: 1;
}
li:nth-child(20n+2){
  --hue: 2;
}
li:nth-child(20n+3){
  --hue: 3;
}
li:nth-child(20n+4){
  --hue: 4;
}
li:nth-child(20n+5){
  --hue: 5;
}
li:nth-child(20n+6){
  --hue: 6;
}
li:nth-child(20n+7){
  --hue: 7;
}
li:nth-child(20n+8){
  --hue: 8;
}
li:nth-child(20n+9){
  --hue: 9;
}
li:nth-child(20n+10){
  --hue: 10;
}
li:nth-child(20n+11){
  --hue: 11;
}
li:nth-child(20n+12){
  --hue: 12;
}
li:nth-child(20n+13){
  --hue: 13;
}
li:nth-child(20n+14){
  --hue: 14;
}
li:nth-child(20n+15){
  --hue: 15;
}
li:nth-child(20n+16){
  --hue: 16;
}
li:nth-child(20n+17){
  --hue: 17;
}
li:nth-child(20n+18){
  --hue: 18;
}
li:nth-child(20n+19){
  --hue: 19;
}
li:nth-child(20n+20){
  --hue: 20;
}
*/
@media (min-width: 550px) and (max-width: 899px) {
    li {
        margin: 1rem;
        width: calc(50% - 4rem);
        float: left;
        min-height: var(--height);
    }

    li:nth-child(4n+3),
    li:nth-child(4n+4) {
        float: right;
    }

    li:nth-child(4n+1)::before {
        top: calc(var(--height)/2 + var(--unit)/2);
        left: 100%;
    }

    li:nth-child(4n+1)::after {
        top: calc(var(--height)/2);
        left: calc(100% + (var(--unit)));
        border: var(--unit) solid transparent;
        border-left-color: inherit;
    }

    li:nth-child(4n+3)::before {
        top: calc(var(--height)/2 + var(--unit)/2);
        left: -1rem;
    }

    li:nth-child(4n+3)::after {
        top: calc(var(--height)/2);
        left: -3rem;
        border: var(--unit) solid transparent;
        border-right-color: inherit;
    }
}

@media (min-width: 900px) and (max-width: 1199px) {
    li {
        margin: 1rem;
        width: calc(33.33% - 4rem);
        float: left;
        min-height: 7rem;
    }

    li:nth-child(6n+4),
    li:nth-child(6n+5),
    li:nth-child(6n+6) {
        float: right;
    }

    li:nth-child(6n+1)::before,
    li:nth-child(6n+2)::before {
        top: calc(var(--height)/2 + var(--unit)/2);
        left: 100%;
    }

    li:nth-child(6n+1)::after,
    li:nth-child(6n+2)::after {
        top: 3.5rem;
        left: calc(100% + (var(--unit)));
        border: var(--unit) solid transparent;
        border-left-color: inherit;
    }

    li:nth-child(6n+4)::before,
    li:nth-child(6n+5)::before {
        top: calc(var(--height)/2 + var(--unit)/2);
        left: -1rem;
    }

    li:nth-child(6n+4)::after,
    li:nth-child(6n+5)::after {
        top: calc(var(--height)/2);
        left: -3rem;
        border: var(--unit) solid transparent;
        border-right-color: inherit;
    }
}

@media (min-width: 1200px) {
    ol {
        max-width: 1600px;
        margin: 0 auto;
    }

    li {
        margin: 1rem;
        width: calc(25% - 4rem);
        float: left;
        min-height: 7rem;
    }

    li:nth-child(8n+5),
    li:nth-child(8n+6),
    li:nth-child(8n+7),
    li:nth-child(8n+8) {
        float: right;
    }

    li:nth-child(8n+1)::before,
    li:nth-child(8n+2)::before,
    li:nth-child(8n+3)::before {
        top: calc(var(--height)/2 + var(--unit)/2);
        left: 100%;
    }

    li:nth-child(8n+1)::after,
    li:nth-child(8n+2)::after,
    li:nth-child(8n+3)::after {
        top: calc(var(--height)/2);
        left: calc(100% + (var(--unit)));
        border: var(--unit) solid transparent;
        border-left-color: inherit;
    }

    li:nth-child(8n+5)::before,
    li:nth-child(8n+6)::before,
    li:nth-child(8n+7)::before {
        top: calc(var(--height)/2 + var(--unit)/2);
        left: -1rem;
    }

    li:nth-child(8n+5)::after,
    li:nth-child(8n+6)::after,
    li:nth-child(8n+7)::after {
        top: calc(var(--height)/2);
        left: -3rem;
        border: var(--unit) solid transparent;
        border-right-color: inherit;
    }
}

/* button */
.btn.btn__default {
    color: black;
}

.above-heading {
    font-size: 12px;
    font-style: bold;
}

.hero-cta {
    color: white;
}

h2 {
    font-size: 4.2rem;
    line-height: 1.1;
    margin-bottom: 25px;
}

.above-heading {
    color: #5f4dee;
    font: 0.70.75rem/0.075rem "Open Sans", sans-serif;
    text-align: center;
}

h4 {
    margin: 41px 0 0;
}

ul {
    text-align: left;
}

#parent {
    width: 101%;
    height: 226px;
    display: block;
    position: relative;
}

#editor {
    position: absolute;
    /* Added */
    top: 1;
    right: 1;
    bottom: 1;
    left: 1;
    margin: 1 auto;
    border-radius: 9px;
}

.btn-solid-reg {
    cursor: pointer;
}

.score {
    font: 701 1rem/1.625rem "Open Sans", sans-serif;
    letter-spacing: 1.1px;
}

.divider {
    height: 2px;
    margin-top: 2.75rem;
    margin-bottom: 3rem;
    border: none;
    background-color: #ccd4df;
}

.box {
    display: block;
    max-width: 20rem;
    margin-right: auto;
    margin-bottom: 4rem;
    margin-left: auto;
    border: 2px solid #ccd3df;
    border-radius: 1.375rem;
    ;
    display: inline-block;
    margin: 11px;
    padding: 16px;
}

.testimonial-item__avatar {
    height: 6rem;
    width: 6rem;
    position: absolute;
    top: -6.4rem;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    border-radius: 50%;
    border: 0px;
}

:link {
    color: #0000EE;
}

:visited {
    color: #551A8B;
}
</style>
